import React, { useState, useEffect } from "react";
import "./Styles/Clock.css";
import TestC from "./Assets/testcolor.png";
import AssgnC from "./Assets/assignmentcolor.png";
import DissC from "./Assets/dissertationcolor.png";
import QuizC from "./Assets/quizcolor.png";
import Parrot from "./Assets/parrot.gif";
import {
  MdOutlineAssignment,
  MdOutlineComputer,
  MdOutlineQuiz,
} from "react-icons/md";
import { BsJournalBookmark } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Clock() {
  const [itemActive, setItemActive] = useState("default");
  const [promptActive, setPromptActive] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const handleItemClick = (item) => {
    setItemActive(item);
    setPromptActive(false);
  };

  return (
    <section className="clock-container">
      <div
        className={
          itemActive === "default"
            ? "clock-compass-wrapper-la"
            : "clock-compass-wrapper-sm"
        }
      >
        <div className="clock-compass">
          <IconContext.Provider value={{ className: "compass-icons" }}>
            <div
              className="clock-compass-marks"
              onClick={() => handleItemClick("assignment")}
            >
              <MdOutlineAssignment />
              <span>Assignments</span>
            </div>
            <div
              className="clock-compass-marks"
              onClick={() => handleItemClick("test")}
            >
              <MdOutlineComputer />
              <span>Exams</span>
            </div>
            <div
              className="clock-compass-marks"
              onClick={() => handleItemClick("quiz")}
            >
              <MdOutlineQuiz />
              <span>Quiz Assessments</span>
            </div>
            <div
              className="clock-compass-marks"
              onClick={() => handleItemClick("project")}
            >
              <BsJournalBookmark />
              <span>Dissertations</span>
            </div>
          </IconContext.Provider>
          <div className={`clock-compass-needle-${itemActive}`}>
            <div className="clock-compass-needle-a"></div>
            <div className="clock-compass-needle-b"></div>
          </div>
        </div>
        <div
          className={itemActive === "default" ? "smol-inactive" : "smol-active"}
          onClick={() => setItemActive("default")}
        ></div>
      </div>

      {/* Conditional Rendering of Cards */}
      {itemActive === "assignment" && (
        <Card
          image={AssgnC}
          title="Assignments"
          color="#7bacd4"
          description="Our team manages the assignment and works with in-depth proficiency. The brief, learning outcomes and rubrics will be followed extensively to give relevant and quality work."
          linkText="Check our Samples"
        />
      )}
      {itemActive === "test" && (
        <Card
          image={TestC}
          title="Exams"
          color="#a6f8bd"
          description="Our experts have cracked some of the toughest entrance examinations in the world. Our unique approach, expertise and teamwork will take care of your exam worries."
          linkText="Check our Samples"
        />
      )}
      {itemActive === "quiz" && (
        <Card
          image={QuizC}
          title="Online Quizzes"
          color="#ff725e"
          description="Our team has over a million hours of quiz-taking experience. You can see numerous quiz screenshots on our testimonials page."
          linkText="Check Testimonials"
        />
      )}
      {itemActive === "project" && (
        <Card
          image={DissC}
          title="Dissertations"
          color="#ffc727"
          description="Research Topics, Methodology, Literature survey, and strict deadlines worrying you? Not anymore. Contact us now."
          linkText="Check our Samples"
        />
      )}

      {promptActive && (
        <PromptCard
          image={Parrot}
          message="Hi, Nancy here. Click on the markings in the compass to check out our services."
          onClose={() => setPromptActive(false)}
        />
      )}
    </section>
  );
}

// Reusable Card Component
const Card = ({ image, title, color, description, linkText }) => (
  <div className="clock-card">
    <div className="clock-card-img">
      <img src={image} alt={title} />
    </div>
    <div className="clock-card-header">
      <p style={{ color }}>{title}</p>
    </div>
    <div className="clock-card-txt">
      <p>{description}</p>
      <br />
      <p>
        {linkText.includes("Check our") ? (
          <Link to="/samples" style={{ textDecoration: "none", color }}>
            {linkText}
          </Link>
        ) : (
          <Link to="/testimonials" style={{ textDecoration: "none", color }}>
            {linkText}
          </Link>
        )}
      </p>
    </div>
  </div>
);

// Reusable PromptCard Component
const PromptCard = ({ image, message, onClose }) => (
  <div className="clock-prompt-card" data-aos="slide-right">
    <div className="clock-prompt-img">
      <img src={image} alt="Prompt" />
    </div>
    <div className="clock-prompt-content">
      <p>
        <strong>{message.split(".")[0]}</strong>
      </p>
      <p>{message.split(".")[1]}</p>
      <button className="clock-prompt-btn" onClick={onClose}>
        Okay
      </button>
    </div>
  </div>
);
