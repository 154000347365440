import image1 from '../Assets/testimonials/Landscape 1.jpeg';
import image2 from '../Assets/testimonials/Landscape 2.jpeg';
import image3 from '../Assets/testimonials/Landscape 3.jpeg';
import image4 from '../Assets/testimonials/Landscape 4.jpeg';
import image7 from '../Assets/testimonials/Landscape 7.jpeg';
import image8 from '../Assets/testimonials/Landscape 8.jpeg';
import image9 from '../Assets/testimonials/Landscape 9.jpeg';
import image13 from '../Assets/testimonials/Landscape 13.jpeg';
import image11 from '../Assets/testimonials/Landscape 11.jpeg';
import image12 from '../Assets/testimonials/Landscape 12.jpeg';
import image15 from '../Assets/testimonials/Landscape 15.jpeg';
import image16 from '../Assets/testimonials/Portrait 1.jpeg';
import image17 from '../Assets/testimonials/Portrait 2.jpeg';
import image18 from '../Assets/testimonials/Portrait 3.jpeg';
import image19 from '../Assets/testimonials/Portrait 4.jpeg';
import image20 from '../Assets/testimonials/Portrait 5.jpeg';
import image21 from '../Assets/testimonials/Portrait 6.jpeg';


const images = [image1,image2,image3,image4,image7,image8,image9,image13,image11,image12,image15,image16,image17,image18,image19,image20,image21]
export default images

